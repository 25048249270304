exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fine-tuning-index-tsx": () => import("./../../../src/pages/fine-tuning-index.tsx" /* webpackChunkName: "component---src-pages-fine-tuning-index-tsx" */),
  "component---src-pages-fine-tuning-leaderboard-tsx": () => import("./../../../src/pages/fine-tuning-leaderboard.tsx" /* webpackChunkName: "component---src-pages-fine-tuning-leaderboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lora-land-tsx": () => import("./../../../src/pages/lora-land.tsx" /* webpackChunkName: "component---src-pages-lora-land-tsx" */),
  "component---src-pages-lora-land-unavailable-tsx": () => import("./../../../src/pages/lora-land-unavailable.tsx" /* webpackChunkName: "component---src-pages-lora-land-unavailable-tsx" */),
  "component---src-pages-models-tsx": () => import("./../../../src/pages/models.tsx" /* webpackChunkName: "component---src-pages-models-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-smallcon-tsx": () => import("./../../../src/pages/smallcon.tsx" /* webpackChunkName: "component---src-pages-smallcon-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-coming-soon-tsx": () => import("./../../../src/templates/coming-soon.tsx" /* webpackChunkName: "component---src-templates-coming-soon-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-form-page-tsx": () => import("./../../../src/templates/form-page.tsx" /* webpackChunkName: "component---src-templates-form-page-tsx" */),
  "component---src-templates-large-language-models-tsx": () => import("./../../../src/templates/large-language-models.tsx" /* webpackChunkName: "component---src-templates-large-language-models-tsx" */),
  "component---src-templates-legal-pages-tsx": () => import("./../../../src/templates/legal-pages.tsx" /* webpackChunkName: "component---src-templates-legal-pages-tsx" */),
  "component---src-templates-list-page-tsx": () => import("./../../../src/templates/list-page.tsx" /* webpackChunkName: "component---src-templates-list-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-thank-you-tsx": () => import("./../../../src/templates/thank-you.tsx" /* webpackChunkName: "component---src-templates-thank-you-tsx" */),
  "component---src-templates-use-case-tsx": () => import("./../../../src/templates/use-case.tsx" /* webpackChunkName: "component---src-templates-use-case-tsx" */)
}

